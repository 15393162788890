<template>
  <div>
    <span
      v-if="
        myOrder.payment_status != 'refunding' &&
          myOrder.payment_status != 'refunded'
      "
    >
      <v-btn small @click="showRefund = true" color="gray"
        >Refund <v-icon right small color="red">mdi-currency-usd</v-icon></v-btn
      >
    </span>
    <span
      v-else-if="myOrder.payment_status === 'refunding'"
      class="grey--text font-weight-thin font-italic"
    >
      Refunding in Process
    </span>
    <span
      v-else-if="myOrder.payment_staus === 'redfunded'"
      class="grey--text font-weight-thin font-italic"
    >
      Refund Successful
    </span>
    <v-dialog v-model="showRefund" max-width="400">
      <v-card>
        <v-card-title>
          Submit Refund.
        </v-card-title>
        <v-card-text class="body-2"
          >Refunds take 5-10 days to appear on a customer's
          statement.</v-card-text
        >
        <v-card-text>
          <v-text-field
            :prefix="refundAmtType"
            :label="refundAmtType === '$' ? 'Amount refund' : 'Percent refund'"
            type="number"
            v-model="refundAmt"
            :rules="[refundRules]"
            class="mb-4"
          ></v-text-field>
          Max refund
          {{ maxRefund | currency }}
          <v-text-field
            maxlength="250"
            label="Reason"
            v-model="refundMsg"
          ></v-text-field>
          <v-checkbox
            v-if="isAdmin"
            v-model="reverse_transfer"
            label="Reverse Transfer"
            persistent-hint
            hint="Also deduct from restaurant's bank (if unchecked, only TakeIn is respobile)"
          ></v-checkbox>
          <template v-if="false">
            <!-- refund type and amount fields -->
            <small>Refund by amount or percentage:</small>
            <v-radio-group v-model="refundAmtType" row class="mt-0">
              <v-radio
                label="Amount"
                value="$"
                class="fsx-12"
                color="primary"
              ></v-radio>
              <v-radio
                label="Percent"
                value="%"
                class="fsx-12"
                color="primary"
              ></v-radio>
            </v-radio-group>
          </template>

          <template v-if="false">
            <small>Refund by cash or coupon:</small>
            <v-radio-group
              v-model="refundType"
              row
              color="primary"
              class="mt-0"
            >
              <v-radio
                label="Money"
                value="money"
                class="fsx-12"
                color="primary"
              ></v-radio>
              <v-radio
                label="Coupon"
                value="coupon"
                class="fsx-12"
                color="primary"
              ></v-radio> </v-radio-group
          ></template>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.stop="showRefund = false"
            >Cancel
          </v-btn>
          <v-btn
            color="success"
            @click.stop="refundOrder"
            :disabled="typeof refundRules === 'string'"
          >
            Refund
            <v-icon dark right>attach_money</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import coupon from 'coupon-code'
export default {
  props: {
    maxRefund: {
      type: Number,
      default: 0.0
    },
    myOrder: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin'
    }),
    refundRules() {
      if (this.refundAmt) {
        if (this.refundAmt <= 0) {
          return 'Refund amount must be greater than 0'
        }
        if (this.refundAmtType === '$' && this.refundAmt > this.maxRefund) {
          return 'Refund amount cannot be greater than the subtotal'
        }
        if (this.refundAmtType === '%' && this.refundAmt > 100) {
          return 'Refund percentage cannot be greater than 100'
        }
      }
      if (!this.refundAmt) {
        return 'Refund amount cannot be empty'
      }
      return true
    }
  },
  watch: {
    openRefund() {
      this.showRefund = this.showRefund
    }
  },
  data() {
    return {
      showRefund: false,
      reverse_transfer: false,
      refundMsg: null,
      refundAmtType: '$',
      refundAmt: null,
      refundType: 'money'
    }
  },
  methods: {
    refundOrder() {
      let totalRefund =
        this.refundAmtType == '$'
          ? this.refundAmt
          : (this.myOrder.payment.all_fees.producer_total * this.refundAmt) /
            100

      let refund = {
        uid: this.myOrder.sellerUserId,
        orderId: this.myOrder.orderId,
        amount: parseFloat(totalRefund),
        message: this.refundMsg,
        amount_type: this.refundAmtType,
        type: this.refundType,
        reverse_transfer: this.reverse_transfer,
        buyer: this.myOrder.buyer
      }
      if (this.refundType === 'coupon') {
        let code = coupon.generate({ partLen: 6 })
        refund.coupond_code = code
        this.$store.dispatch('savePromotion', {
          id: null,
          title: 'Order ' + this.$route.params.order_id + ' refund',
          amount: totalRefund,
          duration: null,
          end_date: null,
          max_redemptions: 1,
          total: 1,
          list: [code],
          all_products: true,

          amount_type: this.refundAmtType,
          type: 'refund'
        })
      }

      this.$store.dispatch('submitRefund', refund).then(this.resetRefund())
    },
    resetRefund() {
      this.showRefund = false
      this.refundAmt = null
      this.refundMsg = null
      this.refundAmtType = '$'
      this.refundType = 'money'
    }
  }
}
</script>

<style lang="scss" scoped></style>
